import { mapState } from "vuex";
export default {
	computed: {
        ...mapState("country/", {
            country: (state) => state.country || "AU",
        }),
        allTimezones() {
            let ukTimezones = [
                "Europe/London",
                "Europe/Monaco",
                "Europe/Madrid",
                "Europe/Malta",
                "Europe/Paris",
                "Europe/Rome",
                "Europe/Athens",
                "Europe/Lisbon",
                "Europe/Dublin",
            ];

            let auTimezones = [
                "Australia/Sydney",
                "Australia/Melbourne",
                "Australia/Brisbane",
                "Australia/Perth",
                "Australia/Adelaide",
            ]

            if (this.country == 'AU') {
                return auTimezones;
            }

            return ukTimezones;
        }
    }
}