<template>
	<div class="content">
		<div class="header d-flex align-items-center profile-header">
			<!-- Mask -->
			<span class="mask bg-gradient-info"></span>
			<!-- Header container -->
			<div class="container-fluid d-flex align-items-center">
				<div class="row">
					<div class="col-lg-12">
						<router-link :to="{ name: 'LeadDetails', params: { id: lead.id }}">
							<h1 class="display-3 text-white">{{ lead.first_name }} {{lead.last_name}}</h1>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<base-header class="pb-6">
			<div class="row align-items-center py-4">
				<div class="col-lg-6 col-7">
					<h6 class="h2 text-white d-inline-block mb-0">Karen</h6>
					<nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
						<route-bread-crumb></route-bread-crumb>
					</nav>
				</div>
			</div>
		</base-header>
		<div class="container-fluid mt--6">
			<div class="card no-border-card">
				<div class="card-header">
					<div class="row">
						<div class="col-3 page-purpose">
							<h3>Manage Lead Recommendations</h3>
						</div>
                        <div class="col-9 text-right">
                            <div class="row">
                                <div class="col" id="search_venue">
                                    <base-input
                                        v-model="searchedVenueName"
                                        append-icon="fas fa-search"
                                        placeholder="Type a venue name and press ENTER"
                                        @keyup.enter="filterByVenue"
                                    ></base-input>
                                    <el-tooltip placement="top" effect="light">
                                        <div slot="content" class="text-center">
                                            Proceed to recommendations <br />submission
                                        </div>
                                        <el-button size="large" @click="showRecommendationSendModal" icon="el-icon-check"
                                            class="show-recommendation-send-modal"
                                            :class="{ 'queue-has-item': recommendations.length}">
                                            {{ recommendations.length ? recommendations.length : 0 }}/6 Selected
                                        </el-button>
                                    </el-tooltip>
                                    <el-button size="large" icon="el-icon-s-operation" @click="showFilter = true" class="show-filters">
                                        Filters
                                    </el-button>
                                </div>
                            </div>
						</div>
					</div>
				</div>
				<div class="card-body px-0 pb-1" v-loading="loading">
					<div class="col-12 mb-4" v-if="! isEmptyResultSet">
						<el-alert type="info" :closable="false" class="venues-alert">
							<strong v-if="recommendations.length < 6">
								Please select up to 6 venues to proceed to recommendations.
							</strong>
							<strong v-if="recommendations.length == 6" class="text-success">
								6/6 Venues are selected.
							</strong>
							Refine filters or proceed to send.
						</el-alert>
					</div>
					<div class="col-12" v-if="! loading && ! isEmptyResultSet('eventCityVenues')" v-loading="loading">
						<div class="row">
							<VenueListing v-for="(items, index) in filteredVenues['eventCityVenues']"
								:key="`venue_group_${items[0].venue_id}`"
                                :rooms="items"
								:currentCountry="currentCountry" :recommendations.sync="recommendations"
								@toggle-drawer="handleToggleDrawer" />
                            </div>
					</div>

                    <div class="row other-city-venues-header med" v-if="! isEmptyResultSet('otherVenues')"
                        :class="roomScoreClasses(filteredVenues['otherVenues'][0][0]['percentile_score'])"
                    >Here are some more venues that are outside the event city ..</div>

                    <div class="col-12" v-if="! loading && ! isEmptyResultSet('otherVenues')" v-loading="loading">
                        <div class="row">
							<VenueListing v-for="(items, index) in filteredVenues['otherVenues']"
								:key="`venue_group_${items[0].venue_id}`"
                                :rooms="items"
								:currentCountry="currentCountry" :recommendations.sync="recommendations"
								@toggle-drawer="handleToggleDrawer" />
                            </div>
					</div>
					<div v-if="!loading && isEmptyResultSet" class="col-12">
						<div class="row p-4 text-danger">
							<b>Sorry! No venues matched your search criteria.</b>
						</div>
					</div>
				</div>
			</div>
		</div>
		<modal :show.sync="modalShowSendRecommendation" body-classes="p-4"
			modal-classes="modal-dialog-centered modal-md">
			<send-recommendation
				:recommendations.sync="recommendations"
				:lead-event-id="$route.params.eventId"
				@previewed="modalShowSendRecommendation = false"
				v-bind:venues.sync="venues"
			/>
		</modal>
		<FilterOptions 
			:show-filter="showFilter" 
			:showFilter.sync="showFilter" 
			:search-criteria="searchCriteria"
			:selectable-locations="cities" 
			:venues.sync="venues" 
			:searchCriteria.sync="searchCriteria"
			:currentSearchCriteria.sync="currentSearchCriteria"
			:defaultSearchCriteria.sync="defaultSearchCriteria"
			@resetSearchFilter="selectDefaultFilter" 
			@selectCurrentFilter="selectCurrentFilter" 
		/>
		<VenueRoomDetails 
			:visible.sync="drawerVisible" 
			:venue-room-name="selectedVenueRoomName"
			:venue-room-description="selectedVenueRoomDescription"
			:venue-room-images="selectedVenueRoomImages"
            :venue-checklist="selectedVenueChecklist"
		/>
		<CorrectInvalidData
			:show-filter="showInitialFilter" 
			:showFilter.sync="showInitialFilter"
            :location-is-invalid="invalidData.location"
            :guest-count-is-invalid="invalidData.guest_count"
            :selectable-locations="cities"
            :venues.sync="venues"
			:searchCriteria.sync="searchCriteria"
            :currentSearchCriteria.sync="currentSearchCriteria"
			:defaultSearchCriteria.sync="defaultSearchCriteria"
        />
	</div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import recommendationMixin from '@/helpers/recommendationMixin.js';
import KarenService from '@/services/karen.service';
import FilterOptions from "@/views/Pages/Karen/FilterOptions.vue";
import CorrectInvalidData from "@/views/Widgets/KarenV2/CorrectInvalidData.vue";
import VenueListing from '@/views/Widgets/KarenV2/VenueListing';
import VenueRoomDetails from '@/views/Widgets/KarenV2/VenueRoomDetails';
import SendRecommendation from "@/views/Widgets/Recommendations/SendRecommendation";
import { Alert, Button } from 'element-ui';

export default {
	components: {
		RouteBreadCrumb,
		VenueListing,
		[Button.name]: Button,
		[Alert.name]: Alert,
		SendRecommendation,
		FilterOptions,
		VenueRoomDetails,
		CorrectInvalidData
	},
	mixins: [recommendationMixin],
    data() {
		return {
			drawerVisible: false,
			showFilter: false,
			showInitialFilter: false,
			loading: false,
			venues: {},
			recommendations: [],
			searchCriteria: {},
			currentSearchCriteria: {},
			defaultSearchCriteria: {},
			cities: [],
			venueStyles: [],
			leadEventId: null,
			modalShowSendRecommendation: false,
			selectedVenueRoomName: '',
			selectedVenueRoomDescription: '',
			selectedVenueRoomImages: [],
            selectedVenueChecklist: [],
            invalidData: {
                location: false,
                guest_count: false
            },
            searchedVenueName: ''
		};
	},
	async mounted() {
		this.loading = true;

        try {
            let results = await this.searchVenues(this.$route.params.id, this.$route.params.eventId);
            if (results.data) {
                this.updateData(results.data);
            }
        } catch (error) {
            if (error.response?.status == 422) {
                const failedDataPoints = error.response.data?.dataPoints;
                if (failedDataPoints.includes('location')) {
                    this.invalidData.location = true;
                }

                if (failedDataPoints.includes('guest_count')) {
                    this.invalidData.guest_count = true;
                }

                if (failedDataPoints.length > 0) {
                    await this.loadEventLocations();
                    this.showInitialFilter = true;
                }
            }
        }

		this.loading = false;
	},
	methods: {
        filterByVenue () {
            if (! this.venues.length) {
                return;
            }


        },
        async loadEventLocations() {
			let response = await KarenService.getSelectableEventLocations(
				this.$route.params.eventId
			);

			if (response?.data?.selectableLocations) {
                this.cities = response.data.selectableLocations;
			}
		},
		showRecommendationSendModal() {
			if (this.recommendations.length) {
				this.modalShowSendRecommendation = true;
			}
		},
		selectDefaultFilter() {
			this.searchCriteria = {};
			this.$nextTick(() => {
				this.searchCriteria = Object.assign({}, this.defaultSearchCriteria);
			});
		},
		selectCurrentFilter() {
			this.searchCriteria = {};
			this.$nextTick(() => {
				this.searchCriteria = Object.assign({}, this.currentSearchCriteria);
			});
		},
		async fetchDefaultSearchCriteria() {
			let response = await KarenService.resetKarenFilterCriteria(this.$route.params.eventId);
			if (response?.data.searchCriteria) {
				this.searchCriteria = response.data.searchCriteria;
			}
		},
		handleToggleDrawer(venue) {
			this.selectedVenueRoomName = venue.room_name;
			this.selectedVenueRoomDescription = venue.room_description;
			this.selectedVenueRoomImages = venue.room_image_gallery;
            this.selectedVenueChecklist = venue.venue_checklist
			this.drawerVisible = true;
		}
	},
	computed: {
        isEmptyResultSet() {
            return (venueGroupName = null) => {
                if (! venueGroupName) {
                    return ! Object.keys(this.venues).length;
                }

                return ! this.venues[venueGroupName]?.length;
            }
        },
        filteredVenues() {
            if (! this.searchedVenueName || this.searchedVenueName.length < 2) {
                return this.venues;
            }

            const stringToMatch = this.searchedVenueName.toLowerCase();
            return {
                eventCityVenues: this.venues['eventCityVenues']?.filter((venueRooms) => venueRooms[0].venue_name.toLowerCase().includes(stringToMatch)),
                otherVenues: this.venues['otherVenues']?.filter((venueRooms) => venueRooms[0].venue_name.toLowerCase().includes(stringToMatch)),
            };
        },
		lead() {
			return this.$store.getters["leads/getLeadById"](this.$route.params.id);
		},
		
		currentCountry() {
			let country = !!this.$store.state.country.country ? this.$store.state.country.country : "au";
			let currencySymbol = !!this.$store.state.country.currency ? this.$store.state.country.currency : "$";
			let measurementUnit = !!this.$store.state.country.measurement ? this.$store.state.country.measurement : "km";
			let countrySpecific = {
				country: country,
				currencySymbol: currencySymbol,
				measurementUnit: measurementUnit
			};
			return countrySpecific;
		}
	}
};
</script>

<style lang="scss">

@import '@/assets/sass/core/colors.scss';

.queue-has-item {
    background-color: #24272C;
    color: #F6F9FC;
}
div.card-header > .row > .col-3.page-purpose {
    display: flex;
    align-items: center;
}
div#search_venue {
    display: flex;
    & > span {
        flex-grow: 1;
        margin-right: 3px;
        & > div.form-group {
            margin-bottom: 0px;
        }
    }
}
.venues-alert {
	background-color: #F6F9FC !important;
	padding: 15px;

	.el-alert__content {
		.el-alert__description {
			font-family: 'Effra';
			font-size: 16px;
			margin-top: 0px;
			color: #24272C;

			strong {
				font-weight: 500;
			}
		}
	}
}

.other-city-venues-header {
    font-size: 24px;
    padding: 20px 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    margin-top: 40px;
    display: flex;
    width: fit-content;
    color: black;
}
</style>
