<template>
  <div class="col-lg-12">
    <h6 class="heading-small">
      Select A Date & Time
    </h6>
    <div class="inspection-date-selector">
      <div class="row">
        <div class="col-7">
          <flat-pickr v-model="date" :config="config" ></flat-pickr>
          <el-select v-model="inspection_timezone" size="mini" filterable placeholder="Inspection timezone">
            <el-option
              v-for="timezone in allTimezones"
              :key="timezone"
              :label="timezone"
              :value="timezone"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-5 time-container" v-if="date">
          <div v-for="(value, key) in getAllTimes" :key="key">
            <el-button
              size="medium" :type="isTimeUnavailable(value) ? 'danger' : 'primary'" :class="(!isShowConfirm(value) ? ' w-100 ' : '') +  ` mb-2 ${key.replace(':', '-')}`" plain
              @click="selectTime(value)" v-if="isTimeAvailable(value)">{{value}}</el-button>
            <el-button
              size="medium" type="primary" v-if="isShowConfirm(value)"
              @click="confirm()" class="timeselectconfirm">confirm</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Select, Option, Button, Notification} from 'element-ui';
import {mapState} from 'vuex';
import moment from 'moment';
import momenttz from 'moment-timezone';
import flatpickr from 'flatpickr';
import timezoneMixin from '@/helpers/timezoneMixin';
export default {
  props: {
    venue: {
      required: true,
      type: Object,
    },
    availability: {
      required: true,
      type: [Object, Array],
    },
  },
  components: {
    flatPickr,
    [Option.name] : Option,
    [Select.name]: Select,
    [Button.name]: Button,
  },
  data: function() {
    let self = this;
    return {
      date: null,
      config: {
        inline: true,
        minDate: "today",
        maxDate:  moment().add(3, 'M').endOf('month').format('Y-MM-DD'),
        disable: [
          function(date) {
            date = moment(date);
            let dateStr = date.format('YYYY-MM-DD');
            if (!self.availability || self.availability.length === 0) {
              return false;
            }
            if (!self.availability.specified_availabilities) {
              return false;
            }
            let availability = self.availability[date.format('ddd').toUpperCase()];
            let overrided_availability = self.availability.overrides ? self.availability.overrides[dateStr] : [];
            if (!availability || overrided_availability === false ) {
              if (overrided_availability  && overrided_availability.length !== 0) {
                return false;
              }
              return true;
            }
            return false;
          },
        ],
      },
      inspection_timezone: '',
      selected_time: '',
    };
  },
  computed: {
    // allTimezones() {
    //   return [
    //     'Australia/Melbourne',
    //     'Australia/Sydney',
    //     'Australia/Brisbane',
    //     'Europe/London',
    //   ];
    //   // return momenttz.tz.names();
    // },
    ...mapState("country/", {
      country: (state) => state.country || 'AU',
    }),
    getAllTimes() {
      let timings = {};
      let time = moment("07", "hh");
      while(true) {
        timings[time.hours().toString() + ':' + time.minutes().toString()] = time.format('hh:mm A');
        time.add(15, 'm');
        if (time.hour() === 20 && time.minute() === 15) {
          break;
        }
      }
      return timings;
    },
  },
  created() {
    this.inspection_timezone = this.availability.timezone;
  },
  mixins: [timezoneMixin],
  methods: {
    confirm() {
      this.$emit('confirm', {
        date:this.date + ' ' + this.selected_time,
        timezone: this.inspection_timezone,
        venue_id: this.venue.id,
      });
    },
    selectTime(time) {
      if (! this.inspection_timezone) {
        Notification.error({
          title: 'Timezone is not selected.',
          message: 'Kindly specify the preferred time zone for the site visit.',
          showClose: false,
        });
        
        return;
      }
      this.selected_time = time;
    },
    isShowConfirm(value) {
      if (value === this.selected_time && this.inspection_timezone) {
        return true;
      }
      return false;
    },
    isTimeAvailable(time) {
      let self = this;
      let dateObj = momenttz.tz(moment(this.date + ' ' + time, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm'), this.inspection_timezone);
      
      let selected_availability = this.availability[dateObj.format('ddd').toUpperCase()];
      let overrided_availability = this.availability && this.availability.overrides ? this.availability.overrides[dateObj.format('YYYY-MM-DD')] : undefined;
      
      let available = true;
      
      if (!available) {
        return false;
      }
      available = false;

      if (this.availability.specified_availabilities === false) {
        return true;
      }

      if (overrided_availability) {
        overrided_availability.forEach((times) => {
          if (!available) {
            available = self.isTimeInBetween(dateObj, times);
          }
          
        });
      } else {
        selected_availability.forEach((times) => {
          if (!available) {
            available = self.isTimeInBetween(dateObj, times);
          }
        });
      }
      return available;
    },
    isTimeUnavailable(time) {
      let available = false;
      let dateObj = momenttz.tz(moment(this.date + ' ' + time, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm'), this.inspection_timezone);
      let unavailable = this.availability.unavailable[dateObj.format('YYYY-MM-DD')];
      let self = this;
      if (unavailable) {
        unavailable.forEach((times) => {
          let timeInBetween = self.isTimeInBetween(dateObj, times);
          if (timeInBetween) {
            available = true;
          }
        });
      }
      return available;
    },
    isTimeInBetween(dateObj, times) {
      let startDate = momenttz.tz(moment(this.date + ' ' + times.start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm'), this.availability.timezone);
      let endDate = momenttz.tz(moment(this.date + ' ' + times.end, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm'), this.availability.timezone);
      if (dateObj.isSameOrAfter(startDate) && dateObj.isSameOrBefore(endDate)) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style>
.inspection-date-selector .flatpickr-input {
    display: none;
}
.inspection-date-selector .flatpickr-calendar {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: none;
}
.inspection-date-selector .flatpickr-months {
  margin-bottom: 15px;
}
.inspection-date-selector .el-select .el-input .el-input__inner, .inspection-date-selector .el-select .el-input.is-focus .el-input__inner:focus {
  border: none;
}
.time-container {
  height: 350px;
  overflow: auto;
}
.flatpickr-day  {
	background: #f0f5ff;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #0860e6;
  margin: 2px;
}
.flatpickr-day:hover  {
  background: #dde9ff;
}
.flatpickr-calendar .flatpickr-day.today {
  background: #dde9ff;
  color: #0860e6;
  border: none;
  font-weight: bold;
}

.flatpickr-calendar .flatpickr-day.today:after {
    content: "\A";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #0060e6;
    display: block;
    position: absolute;
    left: 17px;
    bottom: 5px;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  background: #1171ef;
}



</style>