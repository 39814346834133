<template>
  <div :class="`col-sm-` + (fullWidth ? '12 full-width' : '10')">
    <div class="row">
      <div class="col">
        <h3 class="mb-0">
          {{ leadEvent.name }} <el-tag size="mini">{{ leadEvent.event_type }}</el-tag>
          <a
            v-if="fullWidth" href="javascript:"  class="btn btn-link btn-sm float-right" id="show-timeline"
            @click="timeline">
            Timeline
          </a>
        </h3>
        
      </div>
    </div>
    <div class="row mt-2 sub-details">
      <div class="col-12">
        <div class="row">
          <div class="col">
            <i class="ni ni-calendar-grid-58 mr-2"></i>
            {{ eventDate }}
          </div>
          <div class="col">
            <i class="fa-light fa-users mr-2"></i>
            {{ guestCount }} PAX
          </div>
          <div class="col">
            <i class="fa-light fa-circle-dollar mr-2"></i>
            {{ formattedEstimatedBudget }}
          </div>
        </div>
      </div>
      <div class="col-12 mt-2">
        <div class="row">
          <div class="col-4">
            <i class="ni ni-calendar-grid-58 mr-2"></i>
            Flexible : {{leadEvent.is_date_flexible ? 'Yes' : 'No'}}
          </div>
          <div class="col-8">
            <i class="fa-sharp fa-light fa-bed mr-2"></i>
            Guest Accommodation : {{leadEvent.is_guest_accommodation_quote_required ? 'Yes' : 'No'}}
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="Timeline"
      width="1000px"
      v-loading="loading"
      :visible.sync="showLeadEventTimeline"
      v-if="showLeadEventTimeline"
      :append-to-body="true"
    >
      <div class="row">
        <div class="col-lg-12">
          <LeadEventTimeline :lead-event-id="leadEvent.id"/>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import LeadEventTimeline from "@/views/Widgets/LeadEventDetails/LeadEventTimeline";
import { formatCurrency } from '@/util/formatCurrency';
import { mapState } from "vuex";
import { Tag } from "element-ui";
import moment from 'moment';

export default {
  components: {
    [Tag.name]: Tag,
    LeadEventTimeline,
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
    fullWidth: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showLeadEventTimeline: false,
      loading: false,
    };
  },
  computed: {
    ...mapState("country/", {
      country: (state) => state.country,
    }),
    formattedEstimatedBudget: function() {
        const tsSaleValue = this.leadEvent.latest_tentative_sale?.sale_value;
        return formatCurrency(this.country, tsSaleValue ?? this.leadEvent.estimated_budget);
    },
    eventDate: function() {
        const tsEventDate = this.leadEvent.latest_tentative_sale?.event_date;
        if (tsEventDate) {
            return moment(tsEventDate).format("DD MMM YYYY");
        }

        return  moment(this.leadEvent.event_start).format("DD MMM YYYY");
    },
    guestCount: function() {
        return this.leadEvent.latest_tentative_sale?.guest_count || this.leadEvent.estimated_guests;
    },

  },
  methods: {
    timeline() {
      this.showLeadEventTimeline = true;
    },
  },
};
</script>
<style scoped>
.sub-details {
    color: #bfbfbf;
    font-size: 13px;
}
.full-width .sub-details {
  color: #6f6f6f;
}
</style>