<template>
  <el-form
    class="custom-venue-request custom-venue-container"
    :inline="true"
    :model="formData"
    ref="custom-venue-request"
    v-loading="loading"
  >
    <div>
      <base-input label="Venue Name" >
        <el-input
          placeholder="Please input"
          v-model="formData.name">
        </el-input>
        <span :visible.sync="venueNameExists" v-if="venueNameExists" class="error-message">Venue name already exists as Venuecrew venue.</span>
      </base-input>
      <base-input label="City">
        <el-autocomplete
          v-model="formData.city"
          :fetch-suggestions="querySearch"
          placeholder="Select City"
        >
        </el-autocomplete>
      </base-input>
      <base-input label="Timezone">
        <el-select
            v-model="formData.timezone"
            size="mini"
            filterable
            placeholder="Timezone"
            class="w-100"
        >
            <el-option
                v-for="timezone in allTimezones"
                :key="timezone"
                :label="timezone"
                :value="timezone"
            >
            </el-option>
        </el-select>
      </base-input>
    </div>
    <div class="text-right">
      <base-button
        type="secondary"
        native-type="button"
        id="submit_karen_status_change_request"
        class="mt-4"
        @click="cancel()"
      >
        Cancel
      </base-button>
      <base-button
        type="primary"
        native-type="button"
        id="submit_karen_status_change_request"
        class="mt-4"
        :disabled="disableSubmitButtonIfValidationFails"
        @click.stop="submit()"
      >
        Create
      </base-button>
    </div>
  </el-form>
</template>
<script>
import {
  FormItem,
  Form,
  Input,
  Autocomplete,
  DatePicker,
  Button,
  Select,
  Option
} from "element-ui";
import { CustomVenueService } from "@/services";
import { mapState } from "vuex";
import timezoneMixin from '@/helpers/timezoneMixin';

export default {
  name: "custom-booking",
  components: {
    [FormItem.name]: FormItem,
    [Form.name]: Form,
    [Input.name]: Input,
    [Autocomplete.name]: Autocomplete,
    [DatePicker.name] : DatePicker,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    initialVenueName: {
      type: String,
      required: false,
    }
  },
  data: function() {
    return {
      formData: {
        name: this.initialVenueName,
        city: '',
        country: '',
        timezone: ''
      },
      loading: false,
      venues: [],
      venueNameExists: false
    };
  },
  computed: {
    disableSubmitButtonIfValidationFails() {
      if (! this.formData.name) {
        return true;
      }

      // Check if formData.name already exists in the venues
      const nameExists = this.venues.some(venue => venue.name === this.formData.name);

      if (nameExists) {
        this.venueNameExists = true;
        return true;
      }

      this.venueNameExists = false;

      if (! this.formData.city) {
        return true;
      }

      if (! this.formData.timezone) {
        return true;
      }

      return false;
    },
    ...mapState("country/", {
      country: (state) => state.country,
    }),
    ...mapState({
      venueCities(state) {
        return state.venues.venueCities[this.country] ?? '';
      }
    }),
  },
  mixins: [timezoneMixin],
  methods: {
    submit() {
      let self = this;
      this.formData.country = this.country
      this.loading = true;
      CustomVenueService.createCustomVenue(
        this.formData
      ).then(async (data) => {
        this.loading = false;
        this.$emit('submit', data.custom_venue);
        this.resetForm();
      }).catch((error) => {
        this.loading = false;
        this.$store.dispatch("alert/error", error, { root: true });
      });
    },
    resetForm() {
      this.formData.name = "";
      this.formData.city = "";
      this.formData.timezone = "";
    },
    cancel() {
      this.$emit('cancel');
    },
    querySearch(queryString, cb) {
      var venueCities = this.venueCities.map(item => ({ value: item, key: item }));
      var results = queryString ? venueCities.filter(this.createFilter(queryString)) : venueCities;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return (region) => {
        return (region.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("venues/getAll");
    await this.$store.dispatch("venues/getCities", this.country);
    this.loading = false;
    this.venues = this.$store.state.venues.items;
  },
};
</script>
<style>
.custom-venue-container .el-autocomplete{
  width:100%;
}
.custom-venue-container .el-date-editor{
  width:100%;
}
.custom-venue-container .error-message{
  color:#ff0000;
  font-size: 13px;
}
</style>