<template>
  <el-form
    class="custom-booking-request custom-booking-container"
    :inline="true"
    :model="formData"
    ref="custom-booking-request"
    v-loading="loading"
  >
    <div>
      <base-input label="Venue Name">
        <el-autocomplete
          ref="venueAutocomplete"
          id="select_venue"
          v-model="select_custom_venue_name"
          value-key="name"
          :fetch-suggestions="querySearch"
          placeholder="Select Venue"
          @select="handleSelect"
          @input="handleInput"
        >
          <template slot-scope="{ item }">
            <div>{{ item.name }}</div>
          </template>
        </el-autocomplete>
        <span :visible.sync="empty_venue_message" v-if="empty_venue_message" class="error-message">Please select a venue.</span>
      </base-input>
      <base-input label="Sale Value" >
        <el-input
          placeholder="Please input"
          @keydown.native="allowNumericOnly"
          v-model="formData.sale_value">
          <template slot="prepend">{{currency}}</template>
        </el-input>
      </base-input>
      <base-input label="Commission" >
        <el-input
          placeholder="Please input"
          @keydown.native="allowNumericOnly"
          v-model="formData.commission">
          <template slot="prepend">{{currency}}</template>
        </el-input>
      </base-input>
      <base-input label="Sale Date" >
        <el-date-picker
          v-model="formData.sale_date"
          type="date"
          placeholder="Pick a Date"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
        />
      </base-input>
      <base-input label="Event Date" >
        <el-date-picker
          v-model="formData.event_date"
          type="date"
          placeholder="Pick a Date"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
        />
      </base-input>

    </div>
    <div class="text-right">
      <base-button
        type="secondary"
        native-type="button"
        id="submit_karen_status_change_request"
        class="mt-4"
        @click="cancel()"
      >
        Cancel
      </base-button>
      <base-button
        type="primary"
        native-type="button"
        id="submit_karen_status_change_request"
        class="mt-4"
        :disabled="disableSubmitButtonIfValidationFails"
        @click.stop="showInvoiceForm = true"
      >
        Next
      </base-button>
    </div>
    <el-dialog
      title="Add a custom venue"
      width="400px"
      v-loading="loading"
      :visible.sync="showAddCustomVenueForm"
      v-if="showAddCustomVenueForm"
      :append-to-body="true"
    >
      <div class="row">
        <div class="col-lg-12">
          <add-custom-venue
            :initial-venue-name="enteredVenueName"
            @submit="updateNewlyAddedCustomVenue" 
            @cancel="showAddCustomVenueForm=false" 
          />
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="Confirm Details & Send Invoice Request"
      width="700px"
      v-loading="loading"
      :visible.sync="showInvoiceForm"
      v-if="showInvoiceForm"
      :append-to-body="true"
    >
      <div class="row">
        <div class="col-lg-12">
          <invoice-form
            :lead-event="leadEvent"
            :sale-data="formData"
            :custom-venue-id="formData.custom_venue_id"
            @confirm="confirmFromInvoice" 
            @edit="showInvoiceForm=false" 
          />
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="Mark Venues Lost"
      width="700px"
      v-loading="loading"
      :visible.sync="showMarkMultipleVenuesLostModal"
      v-if="showMarkMultipleVenuesLostModal"
      :append-to-body="true"
    >
      <div class="row">
        <div class="col-lg-12">
          <mark-multiple-venues-lost
              :lead-event="leadEvent"
              @submit="handleMarkVenuesLostSubmit"
              @cancel="handleMarkVenuesLostSubmit"
          />
        </div>
      </div>
    </el-dialog>
  </el-form>
</template>
<script>
import {
  FormItem,
  Form,
  Input,
  Autocomplete,
  DatePicker,
  Button
} from "element-ui";
import { CustomVenueService } from "@/services";
import AddCustomVenue from '@/views/Widgets/Venues/AddCustomVenue.vue';
import InvoiceForm from "@/views/Widgets/LostWon/InvoiceForm.vue";
import MarkMultipleVenuesLost from "@/views/Widgets/LostWon/MarkMultipleVenuesLost.vue";
import { mapState } from "vuex";

export default {
  name: "custom-booking",
  components: {
    [FormItem.name]: FormItem,
    [Form.name]: Form,
    [Input.name]: Input,
    [Autocomplete.name]: Autocomplete,
    [DatePicker.name] : DatePicker,
    [Button.name]: Button,
    AddCustomVenue,
    InvoiceForm,
    MarkMultipleVenuesLost
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
    event_date: {
      required: false,
    },
    initialVenueName: {
      type: String,
      required: false,
    }
  },
  data: function() {
    return {
      formData: {
        status: "won",
        lead_event_id: this.leadEvent.id,
        sale_value: '',
        commission: '',
        sale_value_currency: this.currency,
        custom_venue_id: '',
        sale_date : '',
        event_date: this.event_date,
        send_invoice_email: false
      },
      show_select_venue_text: false,
      empty_venue_message : false,
      select_custom_venue_name : '',
      loading: false,
      selected_custom_venue: '',
      showAddCustomVenueForm: false,
      enteredVenueName: '',
      showInvoiceForm: false,
      showMarkMultipleVenuesLostModal: false
    };
  },
  computed: {
    currency: function() {
      if (this.country && this.country.toLowerCase() === 'uk') {
        return '£';
      } else {
        return '$';
      }
    },
    disableSubmitButtonIfValidationFails() {
      if (!this.formData.custom_venue_id) {
        return true;
      }

      if (! parseFloat(this.formData.sale_value)) {
        return true;
      }

      if (! parseFloat(this.formData.commission)) {
        return true;
      }

      if (! this.formData.sale_date) {
        return true;
      }

      return false;
    },
    ...mapState("country/", {
      country: (state) => state.country,
    }),
    venues() {
      return this.$store.getters["customVenues/getVenues"](this.country);
    },
  },
  methods: {
    submitCustomBooking() {
      if(!this.formData.custom_venue_id) {
        this.empty_venue_message = true;
        this.select_custom_venue_name = "";
        return false;
      }
      let self = this;
      this.loading = true;
      self.formData.sale_value_currency = self.currency;
      return CustomVenueService.createCustomBooking(
        this.formData.custom_venue_id,
        this.leadEvent.id,
        this.formData
      ).then(async () => {
        this.loading = false;
        // this.$emit('submit', this.formData);
        this.resetForm();
      }).catch((error) => {
        this.loading = false;
        this.$store.dispatch("alert/error", error, { root: true });
      });
    },
    resetForm() {
      this.formData.custom_venue_id = "";
      this.select_custom_venue_name = "";
      this.formData.sale_value = "";
      this.formData.commission = "";
      this.enteredVenueName = "";
    },
    async confirmFromInvoice() {
      this.showInvoiceForm = false;
      this.formData.send_invoice_email = true;
      await this.submitCustomBooking();
      this.showMarkMultipleVenuesLostModal = true;
    },
    handleMarkVenuesLostSubmit() {
      this.$emit('submit');
      this.showMarkMultipleVenuesLostModal = false;
    },
    cancel() {
      this.$emit('cancel');
    },
    querySearch(queryString, cb) {
      var venues = this.venues;
      var results = queryString ? venues.filter(this.createFilter(queryString)) : venues;

      // If there are no matches and we have a query string, add the "Create New Venue" option
      if (queryString && results.length === 0) {
        results = [{
          name: `Create new custom venue: "${queryString}"`,
          isCreateNew: true,
          originalName: queryString
        }];
      }

      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return (venue) => {
        return (venue.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      if (item.isCreateNew) {
        this.enteredVenueName = item.originalName;
        this.select_custom_venue_name = "";
        this.formData.custom_venue_id = "";
        this.handleAddVenue();
        this.selected_venue = item;
        return;
      }

      this.formData.custom_venue_id = item.id;
      this.empty_venue_message = false;
    },
    
    handleInput(value) {
      this.enteredVenueName = value;
    },

    allowNumericOnly(event) {
    // Allow key codes for special keys: backspace, delete, left and right arrows, and escape
      const specialKeys = [
        'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Escape',
      ];

      // Check if the pressed key is a special key
      if (specialKeys.includes(event.key)) {
        return;
      }

      // Get the character from the event
      let char = String.fromCharCode(event.keyCode);

      // Regex to check if the character is a number
      const pattern = /[0-9]/;

      // Check if the character is not a number or dot
      if (! (pattern.test(char) || event.keyCode === 190)) {
        event.preventDefault(); // Prevent invalid character input
      }
    },
    handleAddVenue() {
      this.showAddCustomVenueForm = true;
    },
    async updateNewlyAddedCustomVenue(newVenueData) {
      this.showAddCustomVenueForm = false;
      await this.loadVenues();
      this.loading = false;
      this.select_custom_venue_name = newVenueData.name;
      this.handleSelect(newVenueData)
    },
    async loadVenues() {
      this.loading = true;
      return this.$store.dispatch("customVenues/getAll", this.country);
    }
  },

  watch: {
    initialVenueName: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.select_custom_venue_name = newVal;
          this.enteredVenueName = newVal;
          this.$nextTick(() => {
            if (this.$refs.venueAutocomplete) {
              this.$refs.venueAutocomplete.$refs.input.focus();
              this.$refs.venueAutocomplete.activated = true;
            }
          });
        }
      }
    }
  },

  async created() {
    await this.loadVenues();
    this.loading = false;
  },
};
</script>
<style>
.custom-booking-container .el-autocomplete{
  width:100%;
}
.custom-booking-container .el-date-editor{
  width:100%;
}
.custom-booking-container .error-message{
  color:#ff0000;
  font-size: 13px;
}
</style>