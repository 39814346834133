<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Venue Audit Log</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0" id="venue_audit_log_heading">Venue Audit Log ({{this.country}})</h3>
            </div>
          </div>
          <div>
            <div class="col-12 text-right">
              <div class="row">
                <div class="col-10">
                  <base-input
                    v-model="searchQuery"
                    prepend-icon="fas fa-search"
                    placeholder="Press Enter to search..."
                    @keyup.enter="search"
                  ></base-input>
                </div>
                <div class="col-2">
                  <el-switch v-model="showPendingOnly"></el-switch> <span class="text-sm">Pending only</span>
                </div>
              </div>
            </div>
            <el-table
              :data="venueAuditLog"
              row-key="id"
              id="inspections_data_table"
              v-loading="loading"
              header-row-class-name="thead-light"
              @row-click="rowSelected"
            >

              <el-table-column label="Lead Name" prop="first_name" min-width="120px">
                <template v-slot="{ row }">
                  {{ row.first_name + ' ' +  row.last_name}}
                </template>
              </el-table-column>

              <el-table-column label="Sale ID" prop="sale_id" min-width="70px">
                <template v-slot="{ row }">
                  {{ row.id }}
                </template>
              </el-table-column> 
              
              <el-table-column label="Venue Name" prop="venue_name" min-width="180px">
                <template v-slot="{ row }">
                  <span class="font-weight-600 venue_name mb-0 text-sm">{{
                    row.venue_name
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Status"
                prop="status"
                min-width="70px"
              >
                <template v-slot="{ row }">
                  <badge
                    rounded
                    :type="row.status == 'won' ? 'success' : 'danger'"
                  >
                    {{ row.status }}
                  </badge>
                </template>
              </el-table-column>
            
              <el-table-column
                label="Event Date"
                prop="lost_reason"
                min-width="100px"
              >
                <template v-slot="{ row }">
                  {{ (row.lost_reason || row.event_date)}}
                </template>
              </el-table-column>
              <el-table-column
                label="Sale Date"
                prop="lost_details"
                min-width="100px"
              >
                <template v-slot="{ row }">
                  {{ (row.lost_details || row.sale_date)}}
                </template>
              </el-table-column>
              
              <el-table-column label="Sale Value" prop="sale_value"  min-width="90px">
                <template v-slot="{ row }">
                  {{row.sale_value_currency}}{{ row.sale_value }}
                </template>
              </el-table-column>
              <el-table-column label="Commission" prop="commission"   min-width="110px" >
                <template v-slot="{ row }">
                  {{row.sale_value_currency}}{{ row.commission }}
                </template>
              </el-table-column>
              <el-table-column label="Status" prop="approval_status"  >
                <template v-slot="{ row }"> 
                  <el-tooltip placement="top">
                    <div slot="content">{{approval_status(row.approval_status).content}}</div>
                    <i
                      :class="approval_status(row.approval_status).icon" ></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="">
                <template v-slot="{ row }"> 
                  <el-dropdown  class="dropdown" @click.self.stop.prevent="void(0)">
                    <span
                      class="btn btn-sm btn-icon-only text-light" @click.self.stop.prevent="void(0)">
                      <i class="fas fa-cog mt-2" @click.self.stop.prevent="void(0)"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show">
                        <a class="dropdown-item" @click.stop.prevent="updateApprovalStats(row.lead_event_id, row.id, 'approved')"><i class="fa fa-check text-success"></i> Approve</a>
                        <a class="dropdown-item" @click.stop.prevent="updateApprovalStats(row.lead_event_id, row.id, 'denied')"><i class="fa fa-times text-danger"></i> Reject</a>
                        <a class="dropdown-item" @click.stop.prevent="updateApprovalStats(row.lead_event_id, row.id, 'investigating')"><i class="fa fa-question"></i> Investigating</a>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-dialog
      title="Change or Update status."
      width="300px"
      v-loading="loading"
      :visible.sync="venueStatusChange"
      v-if="venueStatusChange"
    >
      <div class="row">
        <div class="col-lg-12">
          <el-button type="success" @click="showStatusWonForm">Won</el-button>
          <el-button type="warning" @click="showStatusLostForm">Lost</el-button>
          <el-button type="danger" v-if="statusChangeRow.status === 'won'" @click="updateStatusUpdateToCancel">Cancel</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="Mark this venue as lost?"
      width="400px"
      v-loading="loading"
      :visible.sync="venueStatusChangeLost"
      v-if="venueStatusChangeLost"
    >
      <div class="row">
        <div class="col-lg-12">
          <lost-reason
            :venue_id="statusChangeVenueId" 
            :lost_reason="statusChangeLostReason" 
            :lost_details="statusChangeLostDetails" 
            :leadEvent="{id: statusChangeLeadEventId}"
            @submit="submitStatusUpdateRequest"
            @cancel="venueStatusChangeLost=false"/>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="Mark this venue as won?"
      width="400px"
      v-loading="loading"
      :visible.sync="venueStatusChangeWon"
      v-if="venueStatusChangeWon"
    >
      <div class="row">
        <div class="col-lg-12">
          <status-win-form
            :edit="true"
            :venue_id="statusChangeVenueId"
            :sale_value="statusChangeSaleValue"
            :commission="statusChangeCommission" 
            :leadEvent="{
              id: statusChangeLeadEventId,
              lead_id: statusChangeLeadId
            }"
            :sale_date ="sale_date" 
            :event_date ="event_date"
            @submit="submitStatusUpdateRequest"
            @cancel="venueStatusChangeWon=false"/>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { 
  Table,
  TableColumn,
  Select,
  Option,
  Tooltip,
  Dropdown,
  DropdownMenu,
  Dialog,
  Switch,
  Button,
  MessageBox
} from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { BasePagination } from "@/components";
import tableMixin from '@/helpers/remoteTableMixin';
import { VenueAuditLog, VenueService } from "@/services";
import LostReason from '@/views/Widgets/LostWon/LostReason.vue';
import StatusWinForm from '@/views/Widgets/LostWon/StatusWinForm.vue';
export default {
  mixins: [tableMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [Dialog.name]: Dialog,
    [Switch.name]: Switch,
    [Button.name]: Button,
    [LostReason.name]: LostReason,
    [StatusWinForm.name]: StatusWinForm,
  },
  data() {
    return {
      venueAuditLog: [],
      loading: true,
      venueStatusChange: false,
      venueStatusChangeWon: false,
      venueStatusChangeLost: false,
      statusChangeRow: {},
      statusChangeVenueId: "",
      statusChangeLeadId: "",
      statusChangeLeadEventId: "",
      event_date: "",
      sale_date: "",
      statusChangeSaleValue: "",
      statusChangeCommission: "",
      statusChangeLostReason:"",
      statusChangeLostDetails: "",
      showPendingOnly: true,
    };
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage(page, search) {
      this.loading = true;
      let venueAuditLogData = await VenueAuditLog.paginate(page, search, this.country, this.showPendingOnly);
      this.venueAuditLog = venueAuditLogData.data;
      this.pagination.total = parseInt(venueAuditLogData.meta.total);
      this.pagination.perPage = parseInt(venueAuditLogData.meta.per_page);
      this.loading = false;
    },
    approval_status(approval_status) {
      let icons = {
        approved: {
          icon: 'fas fa-check text-success',
          content: 'Approved',
        },
        denied: {
          icon: 'fas fa-times text-danger',
          content: 'Denied',
        },
        investigating: {
          icon: 'fas fa-question text-warning',
          content: 'Investigating',
        },
        pending: {
          icon: 'fas fa-minus  text-primary',
          content: 'Pending',
        },
      };
      return icons[approval_status]; 
    },
    async updateApprovalStats(leadEventId, id, status) {
      this.loading = true;
      await VenueAuditLog.updateApproval(leadEventId, id, status);
      await this.loadPage(this.pagination.currentPage, this.searchQuery);
      this.loading = false;
    },
    rowSelected(row) {
      this.statusChangeRow = row;
      this.statusChangeVenueId = row.venue_id;
      this.statusChangeLeadEventId = row.lead_event_id;
      this.statusChangeLeadId = row.lead_id;
      this.venueStatusChange = true;
    },
    async showStatusWonForm() {
      this.statusChangeSaleValue = this.statusChangeRow.sale_value;
      this.statusChangeCommission = this.statusChangeRow.commission;
      this.event_date = this.statusChangeRow.event_date;
      this.sale_date = this.statusChangeRow.sale_date;
      this.venueStatusChangeWon = true;
    },
    async showStatusLostForm() {
      this.statusChangeLostReason = this.statusChangeRow.lost_reason;
      this.statusChangeLostDetails =  this.statusChangeRow.lost_details;
      this.venueStatusChangeLost = true;
    },
    async updateStatusUpdateToCancel() {
      MessageBox.confirm('Are you sure to cancel this sale?', 'Cancel', {
        distinguishCancelAndClose: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async () => {
        this.loading = true;
        await VenueService.changeLeadSaleStatus(
          this.statusChangeVenueId, 
          this.statusChangeLeadEventId,
          {
            status: "cancelled",
          }
        ).catch((error) => {
          this.$store.dispatch('alert/error', error, { root: true });
        });
        await this.submitStatusUpdateRequest();
        this.loading = false;
      });
    },
    async submitStatusUpdateRequest() {
      this.loading = true; 
      await this.loadPage(this.pagination.currentPage, this.searchQuery);
      this.venueStatusChangeLost = false;
      this.venueStatusChangeWon = false;
      this.venueStatusChange = false;
      this.loading = false; 
    },
  },
  computed: {
    country() {
      return this.$route.meta.country.toUpperCase();
    },
  },
  watch: {
    async showPendingOnly () {
      this.loading = true;
      this.searchQuery = '';
      await this.loadPage();
      this.loading = false;
    }, 
    $route(to, from) {
      if(to.meta.country === from.meta.country) {
        this.loadPage(this.pagination.currentPage, this.searchQuery);
      } else {
        this.pagination.currentPage = 1;
        this.loadPage();
      }
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
div.el-table .el-table__row.el-table__row--level-1 {
  background: #5a74ff12;
}
div.el-table .el-table__row.el-table__row--level-1:hover {
  background: #5a74ff0a;
}
</style>