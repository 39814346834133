<template>
  <div id="enquiries" class="p-4">
    <div class="row align-items-center align-item-middle pb-4">
      <div class="col-12">
        <h3 class="mb-0">Enquiries</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <ul class="list-group list-group-flush list my--3" v-loading="loading">
          <li
            v-for="enquiry in enquiries"
            :key="enquiry.id + 'enquiry'"
            class="list-group-item px-0 has-upload has-unavailability-buttons"
          >
            <div class="row align-items-center">
              <div class="col-auto">
                <span class="avatar">
                  <img alt="Image placeholder" :src="enquiry?.venue?.thumbnail || `https://assets.venuecrew.com/images/vc-logo-small.svg`" />
                  <venue-availability v-if="enquiry?.venue && isCorporate" :lead-event="leadEvent" :venue="enquiry?.venue" :isVenueUnavailable="enquiry?.venue.isVenueUnavailable" @update="venueAvailabilityStatusChanged"/>
                </span>
              </div>
              <div class="col details">
                <h5 class="mb-0">{{ enquiry?.venue?.name || "Venue Name Not Available"}}
                    <i v-if="enquiry?.venue?.isLiked" class="fas fa-heart pl-1 text-danger"></i>
                    <i v-if="enquiry?.venue?.isNotPreferred" class="fas fa-xmark-large pl-1 text-danger"></i>
                </h5>
                <span class="venue-booked-by-lead" v-if="enquiry?.venue?.isDirectlyBookedByLead"><b>Marked Booked</b></span>
                <span class="visit-requested-by-lead" v-if="enquiry?.venue?.isVisitRequested"><b>Visit Requested</b></span>
                <span class="quote-requested-by-lead" v-if="enquiry.venue?.isQuoteRequested"><b>Quote Requested</b></span>
                <upload-quote 
                  class="float-right"
                  v-if="enquiry?.venue && isCorporate"
                  :lead-event="leadEvent"
                  :venue="enquiry.venue"
                  :upload-pending="enquiry.venue?.isQuoteRequested"
                  :quote-uploaded="enquiry.venue?.isQuoteUploaded"
                  :quote-last-uploaded="enquiry.venue?.quoteLastUploaded"
                  :quote-downloaded="enquiry.venue?.isQuoteDownloaded"
                  :quote-uploaded-type="enquiry.venue?.quoteType"
                  :quote-uploaded-link="enquiry.venue?.quoteLink"
                  @update="quoteFileUpdated"
                ></upload-quote>
              </div>
            </div>
          </li>
        </ul>
        <p class="text-center mt-5" v-if="enquiries.length == 0">
          No Enquiries       
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import UploadQuote from "@/views/Widgets/LeadEventDetails/UploadQuote";
import EnquiryService from "@/services/enquiry.service";
import VenueAvailability from '@/views/Widgets/LeadEventDetails/VenueAvailability';
export default {
  components: {
    VenueAvailability,
    UploadQuote
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    enquiries() {
      return this.$store.getters["leadEvent/getEnquiriesByLeadEventId"](
        this.leadEvent.id
      );
    },
    isCorporate() {
      return this.leadEvent.event_type == EnquiryService.EVENT_TYPE_CORPORATE;
    }
  },
  async created() {
    this.loadEnquiries();
  },
  methods: {
    async loadEnquiries() {
      this.loading = true;
      await this.$store.dispatch(
        "leadEvent/getEnquiries",
        this.leadEvent.id
      );
      this.loading = false;
    },
    quoteFileUpdated() {
      this.loadEnquiries();
    },
    venueAvailabilityStatusChanged() {
      this.loadEnquiries();
    }
  },
};
</script>
<style scoped>
li .venue-booked-by-lead,
li .visit-requested-by-lead,
li .quote-requested-by-lead,
li .is-guest-accommodation-quote-required {
    background-color: black;
    color: white;
    cursor: default;
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 12px;
    margin-right: 5px;
}
</style>