<template>
  <el-form
    class="custom-site-visit-request custom-site-visit-container"
    :inline="true"
    :model="formData"
    ref="custom-site-visit-request"
    v-loading="loading"
  >
    <div>
      <base-input label="Venue Name" :visible.sync="show_select_venue_text" v-if="show_select_venue_text">
        <el-autocomplete
          id="select_venue"
          ref="venueAutocomplete"
          v-model="select_custom_venue_name"
          value-key="name"
          :fetch-suggestions="querySearch"
          placeholder="Select Venue"
          @select="handleSelect"
          @input="handleInput"
        >
          <template slot-scope="{ item }">
            <div>{{ item.name }}</div>
          </template>
        </el-autocomplete>
        <span :visible.sync="empty_venue_message" v-if="empty_venue_message" class="error-message">Please select a venue.</span>
      </base-input>
      <base-input label="Site visit time" >
        <el-date-picker
            v-model="formData.date_time"
            type="datetime"
            placeholder="Select date and time"
            default-time="12:00:00"
            format="yyyy-MM-dd h:mm a"
            value-format="yyyy-MM-dd h:mm a"
            class="w-100"
        >
        </el-date-picker>
      </base-input>
      <base-input label="Timezone">
        <el-select
            v-model="formData.timezone"
            size="mini"
            filterable
            placeholder="Timezone"
            class="w-100"
        >
            <el-option
                v-for="timezone in allTimezones"
                :key="timezone"
                :label="timezone"
                :value="timezone"
            >
            </el-option>
        </el-select>
      </base-input>
    </div>
    <div class="text-right">
      <base-button
        type="secondary"
        native-type="button"
        id="submit_karen_status_change_request"
        class="mt-4"
        @click="cancel()"
      >
        Cancel
      </base-button>
      <base-button
        type="primary"
        native-type="button"
        id="submit_karen_status_change_request"
        class="mt-4"
        :disabled="disableSubmitButtonIfValidationFails"
        @click.stop="submitWon()"
      >
        Confirm
      </base-button>
    </div>
    <el-dialog
      title="Add a custom venue"
      width="400px"
      v-loading="loading"
      :visible.sync="showAddCustomVenueForm"
      v-if="showAddCustomVenueForm"
      :append-to-body="true"
    >
      <div class="row">
        <div class="col-lg-12">
          <add-custom-venue
            :initial-venue-name="enteredVenueName"
            @submit="updateNewlyAddedCustomVenue" 
            @cancel="showAddCustomVenueForm=false" 
          />
        </div>
      </div>
    </el-dialog>
  </el-form>
</template>
<script>
import {
  FormItem,
  Form,
  Input,
  Autocomplete,
  DatePicker,
  Button,
  Select,
  Option
} from "element-ui";
import { CustomVenueService } from "@/services";
import AddCustomVenue from "@/views/Widgets/Venues/AddCustomVenue.vue";
import { mapState } from "vuex";
import timezoneMixin from '@/helpers/timezoneMixin';

export default {
  name: "custom-site-visit",
  components: {
    [FormItem.name]: FormItem,
    [Form.name]: Form,
    [Input.name]: Input,
    [Autocomplete.name]: Autocomplete,
    [DatePicker.name] : DatePicker,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    AddCustomVenue
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
    initialVenueName: {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      formData: {
        custom_venue_id: '',
        timezone: '',
        date_time: '',
        lead_event_id: this.leadEvent.id,
      },
      show_select_venue_text: false,
      empty_venue_message : false,
      select_custom_venue_name : '',
      loading: false,
      selected_custom_venue: '',
      showAddCustomVenueForm: false,
      enteredVenueName: this.initialVenueName
    };
  },
  computed: {
    currency: function() {
      if (this.selected_custom_venue && this.selected_custom_venue.country && this.selected_custom_venue.country.toLowerCase() === 'uk') {
        return '£';
      } else {
        return '$';
      }
    },
    disableSubmitButtonIfValidationFails() {
      if (! this.formData.timezone) {
        return true;
      }

      if (! this.formData.date_time) {
        return true;
      }

      return false;
    },
    ...mapState("country/", {
      country: (state) => state.country,
    }),
    venues() {
      return this.$store.getters["customVenues/getVenues"](this.country);
    },
  },
  beforeMount() {
    if(!this.venue_id) {
      this.show_select_venue_text = true;
    } 
  },
  mixins: [timezoneMixin],
  methods: {
    submitWon() {
      if(!this.formData.custom_venue_id) {
        this.empty_venue_message = true;
        this.select_custom_venue_name = "";
        return false;
      }
      let self = this;
      this.loading = true;
      CustomVenueService.createCustomSiteVisit(
        this.leadEvent.id,
        this.formData
      ).then(async () => {
        this.loading = false;
        this.$emit('submit', this.formData);
        this.resetForm();
      }).catch((error) => {
        this.loading = false;
        this.$store.dispatch("alert/error", error, { root: true });
      });
    },
    resetForm() {
      this.formData.custom_venue_id = "";
      this.select_custom_venue_name = "";
      this.formData.timezone = "";
      this.formData.date_time = "";
      this.enteredVenueName = "";
    },
    cancel() {
      this.$emit('cancel');
    },
    querySearch(queryString, cb) {
      var venues = this.venues;
      var results = queryString ? venues.filter(this.createFilter(queryString)) : venues;
      
      // If there are no matches and we have a query string, add the "Create New Venue" option
      if (queryString && results.length === 0) {
        results = [{
          name: `Create new custom venue: "${queryString}"`,
          isCreateNew: true,
          originalName: queryString
        }];
      }
      
      cb(results);
    },
    createFilter(queryString) {
      return (venue) => {
        return (venue.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      if (item.isCreateNew) {
        this.enteredVenueName = item.originalName;
        this.select_custom_venue_name = "";
        this.formData.custom_venue_id = "";
        this.handleAddVenue();
        return;
      }
      this.formData.custom_venue_id = item.id;
      this.selected_custom_venue = item;
      this.empty_venue_message = false;
    },
    handleInput(value) {
      this.enteredVenueName = value;
    },
    allowNumericOnly(event) {
    // Allow key codes for special keys: backspace, delete, left and right arrows, and escape
      const specialKeys = [
        'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Escape',
      ];

      // Check if the pressed key is a special key
      if (specialKeys.includes(event.key)) {
        return;
      }

      // Get the character from the event
      let char = String.fromCharCode(event.keyCode);

      // Regex to check if the character is a number
      const pattern = /[0-9]/;

      // Check if the character is not a number or dot
      if (! (pattern.test(char) || event.keyCode === 190)) {
        event.preventDefault(); // Prevent invalid character input
      }
    },
    handleAddVenue() {
      this.showAddCustomVenueForm = true;
    },
    async updateNewlyAddedCustomVenue(newVenueData) {
      this.showAddCustomVenueForm = false;
      await this.loadVenues();
      this.loading = false;
      this.select_custom_venue_name = newVenueData.name;
      this.handleSelect(newVenueData)
    },
    loadVenues() {
      this.loading = true;
      return this.$store.dispatch("customVenues/getAll", this.country);
    }
  },
  async created() {
    await this.loadVenues();
    this.loading = false;
  },
};
</script>
<style>
.custom-site-visit-container .el-autocomplete{
  width:100%;
}
.custom-site-visit-container .el-date-editor{
  width:100%;
}
.custom-site-visit-container .error-message{
  color:#ff0000;
  font-size: 13px;
}
</style>