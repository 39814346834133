<template>
    <div v-loading="loading">
      <el-dialog
        title="Quote Link"
        :visible.sync="dialogVisible"
        width="30%"
        :close-on-click-modal="false"
        :append-to-body="true"
        class="dialog-quote-link-input"
      >
        <el-form @submit.native.prevent="handleLinkSubmit">
          <el-input
            v-model="linkInput"
            placeholder="Enter URL"
            :class="{ 'is-invalid': !isValidUrl && linkInput }"
          >
            <template slot="prepend">https://</template>
          </el-input>
          <div class="quote-link-invalid-feedback" v-if="!isValidUrl && linkInput">
            Invalid URL format
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="handleLinkSubmit" :disabled="!isValidUrl">Confirm</el-button>
        </span>
      </el-dialog>

      <div v-if="uploadPending || !quoteUploaded" >
        <el-dropdown split-button type="primary" size="small" @command="handleDropDownCommand" class="mr-2 hover-button">
          <label :for="`fileUpload-${venue.id}`" style="display: inline">Upload PDF</label>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="uploadLink">Upload Link</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <input 
          :id="`fileUpload-${venue.id}`"
          type="file"
          accept="application/pdf"
          @change="handleFileUpload($event)"
          class="btn btn-primary btn-sm"
          style="display:none"
        />
      </div>
      <div v-else>
        <label class="quote-date">
          <i class="fa-duotone fa-solid fa-file-pdf" @click="downloadQuote" v-if="quoteUploadedType == 'file'"></i>
          <a :href="quoteUploadedLink" target="_blank"><i class="fa-duotone fa-solid fa-link" v-if="quoteUploadedType == 'link'"></i></a>
          {{ convertToUserDate(quoteLastUploaded) }} <i v-if="quoteDownloaded" class="text-success el-icon-circle-check"></i>
        </label>
        <button class="btn btn-sm btn-danger" @click="removeQuote">Remove</button>
      </div>
    </div>
</template>
<script>
import LeadEventService from "@/services/lead-event.service";
import moment from 'moment';
import {
  Dialog,
  Form,
  Input,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  MessageBox
} from "element-ui";

export default {
  components: {
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [Input.name]: Input,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
    venue: {
      type: Object,
      required: true,
    },
    uploadPending: {
      type: Boolean,
      required: false,
    },
    quoteUploaded: {
      type: Boolean,
      required: false,
    },
    quoteLastUploaded: {
      type: String,
      required: false
    },
    quoteDownloaded: {
      type: Boolean,
      required: false,
    },
    quoteUploadedType: {
      type: String,
      required: false,
    },
    quoteUploadedLink: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      linkInput: '',
    };
  },
  computed: {
    isValidUrl() {
      if (!this.linkInput) return true;
      const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(:\d+)?(\/[^\s]*)?$/i;
      return urlPattern.test(this.linkInput);
    }
  },
  methods: {
    handleDropDownCommand(command) {
      if (command == 'uploadLink') {
        this.openLinkInput();
      }
    },
    openLinkInput() {
      this.linkInput = '';
      this.dialogVisible = true;
    },
    handleLinkSubmit() {
      if (!this.isValidUrl || !this.linkInput) return;
      
      const fullUrl = 'https://' + this.linkInput;
      this.loading = true;
      this.dialogVisible = false;
      
      LeadEventService.saveQuoteLink(this.leadEvent.id, this.venue.id, fullUrl)
        .then(() => {
          this.loading = false;
          this.$emit('update');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async handleFileUpload(event) {
      let file = event.target.files[0];
      if (! file) {
        return;
      }
      this.loading = true;
      const formData = new FormData();
      formData.append('quote_file', file);
      formData.append('venue_id', this.venue.id);
      await LeadEventService.uploadQuote(this.leadEvent.id, formData);
      this.loading = false;
      this.$emit('update');
    },

    async downloadQuote() {
      this.loading = true;
      let data = await LeadEventService.getQuoteDownloadUrl(this.leadEvent.id, this.venue.id);
      this.loading = false;

      if (data?.data?.downloadUrl) {
        let downloadUrl = data?.data?.downloadUrl;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    async removeQuote() {
      MessageBox.confirm(
        "Are you sure to delete this quote?",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
        }
      )
        .then(({ value }) => {
            this.requestToDeleteQuote();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async requestToDeleteQuote() {
      this.loading = true;
      await LeadEventService.removeQuote(this.leadEvent.id, this.venue.id);
      this.loading = false;
      this.$emit('update');
    },

    convertToUserDate(utcDate) {
      return moment.utc(utcDate).local().format('DD/MM/YYYY');
    }
  },
  watch: {
    linkInput(val) {
      //remove http or https from the beginning of the link
      if (val.startsWith('http://')) {
        this.linkInput = val.replace('http://', '');
      } else if (val.startsWith('https://')) {
        this.linkInput = val.replace('https://', '');
      }
    }
  }

}
</script>

<style>
.has-upload {
  position: relative;
}

.has-upload:hover .hover-button {
  opacity: 1;
}
</style>
<style scoped>
.hover-button {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.quote-date {
  font-weight: bold;
  font-size: 13px;
  margin-right: 20px;
}

.quote-date i {
  font-size: 20px;
  cursor: pointer;
}

.quote-link-invalid-feedback {
  color: #dc3545;
  font-size: 12px;
  margin-top: 4px;
}

.el-input-group__prepend {
  background-color: #f5f7fa;
  color: #909399;
  font-family: monospace;
}
</style>

<style>
.dialog-quote-link-input .is-invalid input{
  border-color: #dc3545;
  border-width: 1px;
  border-style: solid;
}
</style>