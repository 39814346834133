import { HTTP } from '.';

export default new class TentativeSalesService {
  constructor() {
  }

  // Get a tentative sales for a lead event
  getTentativeSales(leadEventId) {
    return HTTP.get(`/v1/lead-event/${leadEventId}/tentative-sales`);
  }

  // Get a tentative sales default values for a lead event
  getTentativeSaleDefaultValues(leadEventId) {
    return HTTP.get(`/v1/lead-event/${leadEventId}/tentative-sale-default-values`);
  }

  // Get a specific tentative sale by its ID
  getTentativeSaleById(tentativeSaleId) {
    return HTTP.get(`/v1/tentative-sales/${tentativeSaleId}`);
  }

  // Get an invoice for a lead event.
  getInvoice(leadEventId, venueId, customVenueId, commission) {
    if (venueId) {
      return HTTP.get(`/v1/lead-event/${leadEventId}/invoice-details/venues/${venueId}` + (commission ? '?commission=' + commission : ''));
    } else {
      return HTTP.get(`/v1/lead-event/${leadEventId}/invoice-details/custom-venues/${customVenueId}` + (commission ? '?commission=' + commission : ''));
    }
  }

  // Save a new tentative sale for a lead event
  saveTentativeSale(leadEventId, data) {
    return HTTP.post(`/v1/lead-event/${leadEventId}/tentative-sales`, data);
  }

  // Update an existing tentative sale
  updateTentativeSale(tentativeSaleId, data) {
    return HTTP.put(`/v1/tentative-sales/${tentativeSaleId}`, data);
  }

  // Mark venues as lost
  markMultipleVenuesLost(leadEventId, data) {
    return HTTP.post(`/v1/lead-event/${leadEventId}/mark-multiple-venues-lost`, {'venues' : data } );
  }
};
