import Vue from 'vue';
import { TentativeSalesService } from '../services';

export const state = {
  tentativeSalesByLeadEvent: {},
  tentativeSalesById: {},
  invoices: {}
};

export const actions = {
  getTentativeSales({ dispatch, commit }, leadEventId) {
    return TentativeSalesService.getTentativeSales(leadEventId)
      .then((response) => {
        const tentativeSale = response.tentative_sales;
        commit('UpdateTentativeSalesByLeadEvent', { tentativeSale, leadEventId });
        // Also store individual tentative sales by ID
        if (tentativeSale?.id)
          commit('UpdateTentativeSaleById', { tentativeSale });
        return tentativeSale;
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true });
        throw error;
      });
  },

  getTentativeSaleById({ dispatch, commit }, tentativeSaleId) {
    return TentativeSalesService.getTentativeSaleById(tentativeSaleId)
      .then((response) => {
        const tentativeSale = response.data;
        commit('UpdateTentativeSaleById', { tentativeSale });
        return tentativeSale;
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true });
        throw error;
      });
  },

  getInvoice({ dispatch, commit }, {leadEventId, venueId, customVenueId, commission}) {
    return TentativeSalesService.getInvoice(leadEventId, venueId, customVenueId, commission)
      .then((response) => {
        const invoiceData = response.invoiceData;
        commit('UpdateInvoiceData', { invoiceData, leadEventId });
        return invoiceData;
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true });
        throw error;
      });
  },

  saveTentativeSale({ dispatch }, { leadEventId, data }) {
    return TentativeSalesService.saveTentativeSale(leadEventId, data)
      .then((response) => {
        dispatch('alert/success', 'Tentative sale saved successfully', { root: true });
        // Refresh tentative sales list for the lead event
        dispatch('getTentativeSales', leadEventId);
        return response.data;
      })
      .catch((error) => {
        if (error.response?.status != 422) {
          dispatch('alert/error', error, { root: true });
        }
        return error;
      });
  },

  updateTentativeSale({ dispatch }, { tentativeSaleId, leadEventId, data }) {
    return TentativeSalesService.updateTentativeSale(tentativeSaleId, data)
      .then((response) => {
        dispatch('alert/success', 'Tentative sale updated successfully', { root: true });
        // Refresh tentative sales list for the lead event
        dispatch('getTentativeSales', leadEventId);
        return response.data;
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true });
        throw error;
      });
  },
};

export const mutations = {
  UpdateTentativeSalesByLeadEvent(state, { tentativeSale, leadEventId }) {
    if (tentativeSale?.id)
      Vue.set(state.tentativeSalesByLeadEvent, leadEventId, tentativeSale);
  },
  UpdateTentativeSaleById(state, { tentativeSale }) {
    Vue.set(state.tentativeSalesById, tentativeSale?.id, tentativeSale);
  },
  UpdateInvoiceData(state, { invoiceData, leadEventId }) {
    Vue.set(state.invoices, leadEventId, invoiceData);
  }
};

export const getters = {
  getTentativeSalesByLeadEventId: (state) => (leadEventId) => {
    return state.tentativeSalesByLeadEvent[leadEventId] || {};
  },
  getInvoiceByLeadEventId: (state) => (leadEventId) => {
    return state.invoices[leadEventId] || {};
  },
  getTentativeSaleById: (state) => (id) => {
    return state.tentativeSalesById[id];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
