<template>
  <div class="event-details-wrapper">
    <div class="not-synced-alert" v-if="! leadEvent.is_pipeline_attached">
      <el-alert
        title=""
        type="warning"
        center
        :closable="false">
        This event has not been synchronized as a deal in HubSpot. Please review the data, and then save it to submit the information to HubSpot.
        <a class="sync" @click="leadEventEdit = true">Sync</a>
      </el-alert>
    </div>

    <div class="event-details">
      <LeadEventItem :lead-event="leadEvent" :full-width="true"></LeadEventItem>
      <hr class="mx-3 my-3"/>
      <tentative-sales :lead-event="leadEvent"/>
    </div>
    <enquiries :lead-event="leadEvent"></enquiries>
    <recommendations :lead-event="leadEvent"></recommendations>
    <inspections :lead-event="leadEvent"></inspections>
    <el-dialog
      title="Edit Event"
      width="800px"
      :visible.sync="leadEventEdit"
      v-if="leadEventEdit"
      :append-to-body="true"
    >
      <edit-lead-event :lead-event="leadEvent" @onSave="leadEventEdit = false;"></edit-lead-event>
    </el-dialog>
  </div>
</template>
<script>
import EditLeadEvent from '@/views/Widgets/LeadDetails/EditLeadEvent';
import Inspections from '@/views/Widgets/LeadEventDetails/Inspections';
import Recommendations from '@/views/Widgets/LeadEventDetails/Recommendations';
import TentativeSales from '@/views/Widgets/LeadEventDetails/TentativeSales';
import Enquiries from '@/views/Widgets/LeadEventDetails/Enquiries';
import LeadEventItem from '@/views/Widgets/LeadDetails/LeadEventItem';
import { Alert, Dialog } from 'element-ui';
export default {
  components: {
    Recommendations,
    LeadEventItem,
    Inspections,
    Enquiries,
    EditLeadEvent,
    TentativeSales,
    [Alert.name]: Alert,
    [Dialog.name]: Dialog,
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      leadEventEdit: false,
    };
  },
};
</script>
<style scoped>
.event-details {
  padding: 12px 6px;
  background: #ececec;
  margin: 0 10px;
}
.event-details-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  bottom: 0;
  overflow: auto;
}
.not-synced-alert {
  margin-bottom: 10px;
}
.not-synced-alert .sync {
  color: #0b68e1;
  font-weight: bold;
}
</style>