<template>
    <el-form
        class="invoice-form"
        ref="invoice-form"
        v-loading="loading"
    >
        <div class="form-content">
            <div class="section">
                <h4>Invoice Template</h4>
                <div class="emails mt-3 mb-3">
                    <p>To: {{ data.emailTo }}</p>
                    <p>Cc: {{ data.emailToCc }}</p>
                </div>
                <div class="template-container">
                    <p class="template-header">Hi Jenny,</p>
                    <p class="template-text">Can you please create a PPA invoice for the below:</p>
                    <div class="invoice-details">
                        <div class="invoice-line">
                            <span class="invoice-label">Sales Rep:</span>
                            <span class="invoice-value">{{ data.salesRep }}</span>
                        </div>
                        <div class="invoice-line">
                            <span class="invoice-label">Venue Booked:</span>
                            <span class="invoice-value">{{ data.venueBooked }}</span>
                        </div>
                        <div class="invoice-line">
                            <span class="invoice-label">Couple Name:</span>
                            <span class="invoice-value">{{ data.coupleName }}</span>
                        </div>
                        <div class="invoice-line">
                            <span class="invoice-label">Company Name:</span>
                            <span class="invoice-value">{{ data.companyName }}</span>
                        </div>
                        <div class="invoice-line">
                            <span class="invoice-label">Event Name:</span>
                            <span class="invoice-value">{{ data.eventName }}</span>
                        </div>
                        <div class="invoice-line">
                            <span class="invoice-label">Event Date:</span>
                            <span class="invoice-value">{{ data.eventDate | moment('DD-MMM-YYYY') }}</span>
                        </div>
                        <div class="invoice-line">
                            <span class="invoice-label">Event Type:</span>
                            <span class="invoice-value" style="text-transform: capitalize;">{{ data.eventType }}</span>
                        </div>
                        <div class="invoice-line">
                            <span class="invoice-label">Sale ID:</span>
                            <span class="invoice-value" v-if="!data.saleId">
                                <b>[ will populate on submit ]</b>
                            </span>
                            <span class="invoice-value" v-else>{{ data.saleId }}</span>
                        </div>
                        <div class="invoice-line">
                            <span class="invoice-label">CTL:</span>
                            <span class="invoice-value">{{ data.ctl }}</span>
                        </div>
                        <div class="invoice-line">
                            <span class="invoice-label">CTL Rep:</span>
                            <span class="invoice-value">{{ data.ctlRep }}</span>
                        </div>
                        <div class="invoice-line">
                            <span class="invoice-label">Comms %:</span>
                            <span class="invoice-value">{{ data.commsPercentage }}</span>
                        </div>
                        <div class="invoice-line">
                            <span class="invoice-label">Comms {{data.currencySign}} inc {{data.taxType}}:</span>
                            <span class="invoice-value">{{ data.commsAmountDisplay }}</span>
                        </div>
                        <div class="invoice-line">
                            <span class="invoice-label">Sale amount:</span>
                            <span class="invoice-value">{{ data.saleAmount }}</span>
                        </div>
                        <div class="invoice-line">
                            <span class="invoice-label">Payment Terms:</span>
                            <span class="invoice-value">{{ data.paymentTerms }}</span>
                        </div>
                    </div>
                    <p class="template-footer">Thank you!<br>{{ data.salesRep }}</p>
                </div>
            </div>

            <div class="section">
                <h4>Slack Template</h4>
                <div class="slack-template">
                    <el-input
                        v-model="slackMessage"
                        readonly
                    >
                        <template slot="append">
                            <el-button @click="copyToClipboard">
                                <i class="fas fa-copy"></i>
                            </el-button>
                        </template>
                    </el-input>
                </div>
            </div>

            <div class="actions">
                <base-button
                    type="secondary"
                    @click="edit"
                >
                   {{ preview ? 'Close' : 'Edit' }}
                </base-button>
                <base-button
                    v-if="!preview"
                    type="primary"
                    class="ml-2"
                    @click="confirm"
                >
                    Confirm
                </base-button>
            </div>
        </div>
    </el-form>
</template>

<script>
import { 
    Input,
    Form,
    FormItem,
    Button,
    Message
} from 'element-ui';

import moment from 'moment';
import { mapState } from 'vuex';

export default {
    name: 'InvoiceForm',
    components: {
        [Input.name]: Input,
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Button.name]: Button
    },
    props: {
        leadEvent: {
            type: Object,
            required: true,
        },
        venueId: {
            type: Number,
            required: false,
        },
        customVenueId: {
            type: Number,
            required: false,
        },
        saleData: {
            type: Object,
            required: false,
        },
        preview: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            data: {
                salesRep: '',
                venueBooked: '',
                coupleName: '',
                companyName: '',
                eventName: '',
                eventDate: '',
                eventType: '',
                saleId: '',
                ctl: '',
                ctlRep: '',
                commsPercentage: '',
                commsAmount: '',
                commsAmountDisplay: '',
                commsAmountExGst: '',
                saleAmount: '',
                paymentTerms: '',
                currencySign: '',
                taxType: '',
                emailTo: '',
                emailToCc: ''
            },
            loading: false
        }
    },
    computed: {
        ...mapState("country/", {
            country: (state) => state.country || "AU",
        }),
        slackMessage() {
            let clientName = this.data.eventType == 'corporate' ? this.data.companyName : this.data.coupleName;
            if (! this.data.commsAmountExGst) {
                return `${clientName} - ${this.data.venueBooked} - ${this.data.currencySign}${this.data.commsAmount} Inc ` + this.data.taxType;
            }
            return `${clientName} - ${this.data.venueBooked} - ${this.data.currencySign}${this.data.commsAmountExGst}`;
        },
        invoiceData() {
            return this.$store.getters["tentativeSales/getInvoiceByLeadEventId"](this.leadEvent.id);
        }
    },
    async created() {
        this.loading = true;
        await this.$store.dispatch(
            'tentativeSales/getInvoice',
            {
                leadEventId: this.leadEvent.id,
                venueId: this.venueId,
                customVenueId: this.customVenueId,
                commission: this.saleData?.commission
            });
        this.loadValuesToForm();
        this.loading = false;
    },
    methods: {
        currencyFormat(number) {
            return this.invoiceData.saleValueCurrency + (new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }).format(number));
        },
        copyToClipboard() {
            navigator.clipboard.writeText(this.slackMessage).then(() => {
                Message.success({
                    message: 'Copied to clipboard!',
                    type: 'success'
                });
            }).catch(() => {
                Message.error({
                    message: 'Failed to copy',
                    type: 'error'
                });
            });
        },
        edit() {
            this.$emit('edit');
        },
        confirm() {
            this.$emit('confirm');
        },
        loadValuesToForm() {
            this.data.salesRep = this.invoiceData.salesRepName;
            this.data.venueBooked = this.invoiceData.venueName;
            this.data.coupleName = this.invoiceData.eventType == 'wedding' 
                ? this.invoiceData.leadFullName + (this.invoiceData.partnerFullName ? ' & ' + this.invoiceData.partnerFullName : '')
                : 'N/A';
            this.data.companyName = this.invoiceData.companyName || 'N/A';
            this.data.eventName = this.invoiceData.eventName;
            this.data.eventDate = this.invoiceData.eventDate;
            this.data.eventType = this.invoiceData.eventType;
            this.data.saleId = this.invoiceData.saleId;
            this.data.ctl = this.invoiceData.ctl ? 'YES' : 'NO';
            this.data.ctlRep = this.invoiceData.ctlRep;
            this.data.commsPercentage = this.invoiceData.commsPercentage;
            this.data.taxType = (this.country == 'AU') ? 'GST' : 'VAT';
            let commissionDetails = this.currencyFormat(this.invoiceData.commsAmount) +
            ((this.invoiceData.commsAmountExGst) ? ' / ' + this.currencyFormat(this.invoiceData.commsAmountExGst) + ' ex ' + this.data.taxType : '');

            this.data.commsAmount = this.invoiceData.commsAmount;
            this.data.commsAmountDisplay = commissionDetails;
            this.data.commsAmountExGst = this.invoiceData.commsAmountExGst;
            this.data.saleAmount = this.currencyFormat(this.saleData?.sale_value || this.invoiceData.saleAmount);
            this.data.paymentTerms = this.invoiceData.paymentTerms;;
            this.data.currencySign = this.invoiceData.saleValueCurrency;
            this.data.emailTo = this.invoiceData.emailTo;
            this.data.emailToCc = this.invoiceData.emailToCc;
        }
    }
};
</script>

<style>
.invoice-form {
    padding: 20px;
}

.invoice-form h3 {
    margin-bottom: 20px;
    font-weight: 600;
}

.invoice-form .section {
    margin-bottom: 20px;
}

.invoice-form h4 {
    margin-bottom: 10px;
    font-weight: 500;
}

.template-container {
    padding: 20px;
    border-radius: 4px;
    font-size: 15px;
    white-space: pre-wrap;
    border: 1px solid #DCDFE6;
}

.template-container p {
    font-size: 15px;
}

.template-header, .template-text, .template-footer {
    margin-bottom: 15px;
}

.invoice-details {
    margin: 15px 0;
}

.invoice-line {
    margin-bottom: 8px;
    display: flex;
}

.invoice-label {
    font-weight: bold;
    min-width: 150px;
    padding-right: 10px;
}

.invoice-value {
    flex: 1;
}

.invoice-form .slack-template {
    display: flex;
    align-items: center;
}

.invoice-form .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.invoice-form .ml-2 {
    margin-left: 0.5rem;
}
.invoice-form .emails p {
    margin: 0;
    font-size: 14px;
}
</style>
